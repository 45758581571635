<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
            
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.mrdr_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.mrdr_code" 
                    :rules="toValidate(mrValidation.mrdr_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.mrdr_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.mrdr_name" 
                    :rules="toValidate(mrValidation.mrdr_name)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Pilih Fakes<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Fakes" v-model="row.mrdr_fakes" :options="$parent.mFakes" label="text" @input="selectPoli($event)" :clearable="true" :reduce="v=>v.value"></v-select>

                  <VValidate 
                    name="Fakes" 
                    v-model="row.mrdr_fakes" 
                    :rules="toValidate(mrValidation.mrdr_fakes)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Pilih Poli<span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Pilih Poli" v-model="row.mrdr_poli" :options="$parent.mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                  <VValidate 
                    name="Poli" 
                    v-model="row.mrdr_poli" 
                    :rules="toValidate(mrValidation.mrdr_poli)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Kode BPJS<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.mrdr_kode_bpjs" placeholder="e.g 0001"/>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    addRow(){
      let payload = {
        "name":""
      }
      this.row.mrdr_daftar_dokter.push(payload)
    },
    removeRow(k){
      this.row.mrdr_daftar_dokter.splice(k,1)      
    },
    selectPoli(e){
        let data = {
            id: e,
            type: 'select-poli'
        }
        this.row.mrdr_poli = null
        Gen.apiRest(
            "/do/"+ this.$parent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.$parent.mPoli = res.data.mPoli         
        })
    }
  },
}
</script>